import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Section from '../components/Section'
import SectionContent from '../components/Section/Content'
import { css } from 'linaria'
import { TextBlock } from '../components/TextElements'

export default ({ data, location }) => (
  <Layout
    location={location}
    title="Cookie Policy of Bejamas"
    showCtaInHeader={true}
  >
    <Section>
      <SectionContent columns="1">
        <TextBlock
          dangerouslySetInnerHTML={{
            __html: data.iubendaDocument.cookiePolicy.content
          }}
        />
      </SectionContent>
    </Section>
  </Layout>
)

export const query = graphql`
  query CookiePolicyPageQuery {
    iubendaDocument {
      cookiePolicy {
        content
      }
    }
  }
`
